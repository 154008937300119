type StrapiMediaTypes = '.jpg' | '.png'
type StrapiMediaMimeTypes = 'image/png' | 'image/jpeg'
type StrapiMediaFormat = {
  ext: StrapiMediaMimeTypes
  hash: string
  height: number
  mime: StrapiMediaMimeTypes
  name: string
  size: number
  url: string
  width: number
}
type StrapiMediaFormats = {
  medium: StrapiMediaFormat
  small: StrapiMediaFormat
  thumbnail: StrapiMediaFormat
}
export type StrapiMedia = {
  attributes: {
    alternativeText: string
    caption: string
    createdAt: string
    ext: StrapiMediaTypes
    formats: StrapiMediaFormats
    hash: string
    height: number
    mime: StrapiMediaMimeTypes
    name: string
    previewUrl: null
    provider: string
    provider_metadata: null
    size: number
    updatedAt: string
    url: string
    width: number
    id: number
  }
}
const getImageUrl = (image: StrapiMedia, format = null): string => {
  const host = 'https://konfetti-cms.s3.eu-central-1.amazonaws.com/'
  const cdnHost = 'https://d1sos1r10s6do5.cloudfront.net/cms-media/'

  if (!format) {
    return image?.attributes?.url?.replace(host, cdnHost)
  }

  let imgUrl = ['thumbnail', 'small', 'medium', 'large'].includes(format)
    ? image?.attributes?.formats?.[format]?.url?.replace(host, cdnHost)
    : null

  // Sometimes the url attribute isn't available,
  // so we need to manually assemble that
  if (!imgUrl) {
    const formatObj = image?.attributes?.formats?.[format]

    if (formatObj) {
      const hash = formatObj?.hash || ''
      const ext = formatObj?.ext || ''
      imgUrl = `${cdnHost}${hash}${ext}`
    } else {
      imgUrl = image?.attributes?.url
      if (imgUrl) {
        imgUrl = imgUrl.replace(host, cdnHost)
      }
    }
  }

  return imgUrl
}

const getAlternativeText = (image: StrapiMedia): string => {
  return image?.attributes?.alternativeText || ''
}

export const strapiImageGetters = {
  getImageUrl,
  getAlternativeText,
}
